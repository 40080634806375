<template>
  <v-card
    :style="smUp ? 'background-color: transparent; padding-top: 45px; margin-top: 70px':''"
    flat>
      <v-layout
        :style="smUp ? 'max-width: 85%; margin-top: 2.5%': 'margin-top: 2.5%'"
        wrap
        row
        :justify-center="!smUp"
        :justify-start="smUp">
        <v-flex
          xs12
          sm6>
          <v-layout
            row
            :justify-center="!smUp"
            :justify-start="smUp">
            <p
              v-text="'Notifications'"
              class="view_title_text"
            />
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-container>
          <!-- Start table container -->
          <div
            v-if="!bLoading">
            <notifications-table 
              :items="notifications_list"
              @update="get_notifications"
            />
            <v-pagination 
              v-model="current_page" 
              :length="num_pages">
            </v-pagination>
          </div>
          <!-- Loading animation -->
          <v-card
            flat
            style="height: 100%; width: 100%; background-color: transparent"
            v-else>
            <v-layout
              :style="smUp ? 'max-width: 90%; margin-top: 10%':''"
              row
              justify-center>
              <v-progress-circular
                indeterminate
                rotate
                color="primary"
                size="100"
                width="1"
              />
            </v-layout>
          </v-card>
          <!-- Loading animation -->
          <!-- End table container -->
        </v-container>
      </v-layout>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    current_page: 1,
    num_pages: 0,
    notifications_list: [],
    bLoading: false, 
  }),
  mounted () {
    this.get_notifications()
  },
  watch: {
    current_page: function () {
      this.get_notifications()
    }
  },
  methods: {
    get_notifications () {
      this.bLoading = true
      db.get(`${uri}/v2/api/notifications`, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
          'Authorization': this.usr_token
        },
        params: {
          iPage: this.current_page,
          iPageSize: 15
        }
      })
      .then(resp => {
        this.bLoading = false
        this.$store.commit('toggle_alert', {color: 'success', text: resp.data.message})
        this.num_pages = resp.data.iTotalPages
        this.notifications_list = resp.data.notifications
      })
      .catch(err => {
        this.bLoading = false
        this.$store.commit('toggle_alert', {color: 'fail', text: err.response.data.message})
      })
    }
  },
  computed: {
    smUp () {
      return this.$vuetify.breakpoint.smAndUp
    },
    usr_token () {
      return 'Bearer ' + this.$store.state.token
    }
  },
  components: {
    NotificationsTable: () => import('@/components/sections/Admin/Notifications/NotificationsTable')
  }
}
</script>